import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// elementUI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/index.css";
// 百度地图引入
// import BaiduMap from 'vue-baidu-map'
// Vue.use(BaiduMap, { ak: 'jGZAh14AQStlfqr5acVOrmdWYMDrLdC2' })
Vue.use(ElementUI);
import "@/assets/iconfont/iconfont.css";
//api正式
//apk测试
//aph本地
//http封装请求
import axios from "axios";
// axios.defaults.baseURL = "/apk";
axios.defaults.baseURL = "https://www.haneton.com/warrantee/";
axios.defaults.headers["Content-Type"] =
  "application/x-www-form-urlencoded;charset=utf-8";
axios.defaults.withCredentials = true;

Vue.prototype.$http = axios;

//echarts引入
import geoJson from "@/utils/china.json";
echarts.registerMap("china", geoJson);

import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

import "./style/index.css";
Vue.config.productionTip = false;

// 图片懒加载
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload, {
  // 配置加载错误和login图片
  error: "",
  loading: "",
});

// vue-meta-info
import MetaInfo from "vue-meta-info";
Vue.use(MetaInfo);

//分享插件
import Share from "vue-social-share";
import "vue-social-share/dist/client.css";
Vue.use(Share);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
