import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Layout from "@/layout";
const meta = {
  title: "函驿通电子保函平台-在线申请投保保函",
  content: {
    keywords: "函驿通,投标保函办理,履约保函办理,投标保证金保函,银行保函",
    description:
      "函驿通是河北筑正旗下互联网电子保函平台，提供全国投标保函、厦约保函、预付款保函农民工工资保函的在线真伪难辨的问题;帮助企业融通由请保函、在线担保确认、在线银行出函、在线查验保函等服务，帮助投标企业解决纸质保函流程复杂、经营资金、提升资金使用效率，与交易平台对接，项目信息更准确，与银行系统直连，银行保函更放心.",
  },
};
const meta2 = {
  title: "函驿通电子保函平台-在线申请投保保函",
  content: {
    keywords: "函驿通,投标保函办理,履约保函办理,投标保证金保函,银行保函",
    description:
      "函驿通是河北筑正旗下互联网电子保函平台，提供全国投标保函、厦约保函、预付款保函农民工工资保函的在线真伪难辨的问题;帮助企业融通由请保函、在线担保确认、在线银行出函、在线查验保函等服务，帮助投标企业解决纸质保函流程复杂、经营资金、提升资金使用效率，与交易平台对接，项目信息更准确，与银行系统直连，银行保函更放心.",
  },
  activeMenu: "/help/register", //点击子页面定位到父页面css导航样式
};
const routes = [
  {
    path: "",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        meta,
        component: () => import("@/views/homePage/index.vue"),
        name: "homePage",
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/loginPage/login.vue"),
    meta,
  },
  {
    path: "/ydexamine",
    name: "ydexamine",
    component: () => import("@/views/ydexamine/ydexamine.vue"),
    meta,
  },
  {
    path: "/application",
    component: Layout,
    redirect: "/application",
    children: [
      {
        path: "/application",
        component: () => import("@/views/application/index.vue"),
        meta,
        name: "Application",
      },
    ],
  },
  {
    path: "/examine",
    component: Layout,
    redirect: "/examine",
    children: [
      {
        path: "/examine",
        component: () => import("@/views/examine/index.vue"),
        meta,
        name: "Examine",
      },
    ],
  },
  {
    path: "/cooperation",
    component: Layout,
    redirect: "/cooperation",
    children: [
      {
        path: "/cooperation",
        component: () => import("@/views/cooperationPage/index.vue"),
        meta,
        name: "cooperation",
      },
    ],
  },
  {
    path: "/about",
    component: Layout,
    redirect: "/about",
    children: [
      {
        path: "/about",
        component: () => import("@/views/aboutPage/index.vue"),
        meta,
        name: "about",
      },
    ],
  },
  {
    path: "/help",
    component: Layout,
    meta,
    redirect: "/help/register",
    children: [
      {
        path: "/help/register",
        component: () => import("@/views/helpPage/index.vue"),
        name: "/help/register",
        children: [
          {
            // 注册申请
            path: "/help/register",
            name: "register",
            meta: meta2,
            component: () =>
              import("@/views/helpPage/childrenPages/register.vue"),
          },
          {
            // 保函申请
            path: "/help/apply",
            name: "apply",
            meta: meta2,
            component: () => import("@/views/helpPage/childrenPages/apply.vue"),
          },
          {
            // 资料提交
            path: "/help/textAdd",
            name: "textAdd",
            meta: meta2,
            component: () =>
              import("@/views/helpPage/childrenPages/textAdd.vue"),
          },
          {
            // 费用支付
            path: "/help/payment",
            name: "payment",
            meta: meta2,
            component: () =>
              import("@/views/helpPage/childrenPages/payment.vue"),
          },
          {
            // 审核出函
            path: "/help/audit",
            name: "audit",
            meta: meta2,
            component: () => import("@/views/helpPage/childrenPages/audit.vue"),
          },
          {
            // 发票申请
            path: "/help/invoice",
            name: "invoice",
            meta: meta2,
            component: () =>
              import("@/views/helpPage/childrenPages/invoice.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/news",
    component: Layout,
    redirect: "/news",
    children: [
      {
        path: "/news",
        component: () => import("@/views/newsPage/index.vue"),
        meta,
        name: "News",
      },
    ],
  },
  {
    // 隐私政策
    path: "/policy",
    component: () => import("@/views/policyPage/index.vue"),
    meta: { title: "函驿通隐私政策" },
    name: "Policy",
  },
  {
    path: "/newInfo",
    component: Layout,
    redirect: "/newInfo",
    children: [
      {
        path: "/newInfo",
        component: () => import("@/views/newsPage/newInfo/newInfo.vue"),
        name: "NewInfo",
        meta: {
          activeMenu: "/news",
          title: "函驿通电子保函平台-在线申请投保保函",
          content: {
            keywords:
              "函驿通,投标保函办理,履约保函办理,投标保证金保函,银行保函",
            description:
              "函驿通是河北筑正旗下互联网电子保函平台，提供全国投标保函、厦约保函、预付款保函农民工工资保函的在线真伪难辨的问题;帮助企业融通由请保函、在线担保确认、在线银行出函、在线查验保函等服务，帮助投标企业解决纸质保函流程复杂、经营资金、提升资金使用效率，与交易平台对接，项目信息更准确，与银行系统直连，银行保函更放心.",
          },
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});

router.beforeEach((to, from, next) => {
  // console.log("to", to);
  // console.log("from", from);
  // 拦截
  if (to.path == "/hebeiCA") {
    window.open(
      "https://hebcaonline.hebca.com/Hebca/certbussconduct/entry.action?onlineType=4&projectType=zbhyt&cztype=1"
    );
    next(from.path);
  } else if (
    to.path == "/help/register" ||
    to.path == "/help/apply" ||
    to.path == "/help/textAdd" ||
    to.path == "/help/payment" ||
    to.path == "/help/audit" ||
    to.path == "/help/invoice"
  ) {
    // console.log(to.path);
  } else {
    window.scroll(0, 0);
  }
  // 动态修改tdk
  if (to.meta.title) {
    document.title = to.meta.title;
    // console.log(to.meta);
  }
  next();
});

export default router;
