<template>
  <div>
    <headnav></headnav>
    <router-view />
    <lower></lower>
    <!-- 固定 -->
    <div class="fid">
      <Transition name="fade" type="transition">
        <div class="calc" v-show="iscalc">
          <h3>在线保费计算</h3>
          <div class="from">
            <div class="from_top">
              <el-select v-model="value" placeholder="请选择投标保函类型" style="width: 100%;">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-input v-model="input" placeholder="请输入保证金" class="input">
                <template slot="append">元</template>
              </el-input>
              <div class="button">
                <div class="clear">
                  <el-button size="medium" plain style="width: 100%;" @click="btnClear">重置</el-button>
                </div>
                <div class="star">
                  <el-button size="medium" plain style="width: 100%;" @click="btnStar">开始计算</el-button>
                </div>
              </div>
            </div>
            <div class="from_bottom" v-show="isMony">
              <div class="img"><img v-lazy="require('@/assets/application/钱袋.png')" alt=""></div>
              <p v-if="pop">您本次申请的保函所费用约为{{ money }}元</p>
              <p v-else>您所输入的保费属于大额保费请联系客服咨询。电话：400-003-1733。</p>
            </div>
          </div>
        </div>
      </Transition>
      <div>
        <div class="pictrue" @click="calc">
          <img v-lazy="require('@/assets/home/calc.png')" alt="">
        </div>
        <div class="pictrue showImgWechat" @click="wechat" style="display:block;position:relative">
          <div style="position:absolute;bottom:-25px;right:100px;">
            <img src="@/assets/weicode.png" alt="" class="wechatImg">
          </div>
          <img src="@/assets/home/kefu.png" alt="">
        </div>
        <div class="pictrue phoneBoxShowid" @click="phoneBox" style="display:block;position:relative">
          <img v-lazy="require('@/assets/home/phone1.png')" alt="">
          <div class="phoneBoxShow" style="position:absolute;bottom:0px;right:100px;">
            <img src="@/assets/联系电话.png" alt="" class="showImgPhone" style="width:240px;height:90px">
          </div>
        </div>
      </div>
    </div>
    <div v-show="iscalc" class="zhezhao" @click="noShow"></div>
  </div>
</template>

<script>
import Lower from "./components/lower.vue";
import Headnav from "./components/headnav.vue";
export default {
  components: { Headnav, Lower },
  data() {
    return {
      isshowImg: false,
      iscalc: false,
      value: '',
      options: [
        {
          value: '1',
          label: '银行保费'
        },
        {
          value: '2',
          label: '保险保费'
        },
        {
          value: '3',
          label: '担保公司保费'
        },
      ],
      input: '',
      isMony: false,
      money: null,
      pop: true
    };
  },
  created() { },
  mounted() { },
  methods: {
    calc() {
      this.iscalc = !this.iscalc
    },
    btnStar() {
      if (this.value && this.input) {
        this.getList()
      }
    },
    wechat() {
      window.open('https://work.weixin.qq.com/kfid/kfc54a86ff44f606eb2')
      // window.open('https://www.haneton.com/warrantee/loginController.do?loginPage')
    },
    getList() {
      let num = parseFloat(this.input)
      this.pop = true
      if (this.value === '1') {
        if (num <= 200000) {
          this.money = 260
        }
        else if (num > 200000 && num <= 600000) {
          this.money = 300
        }
        else if (num > 600000 && num <= 800000) {
          this.money = 370
        }
        else if (num > 800000) {
          this.pop = false
        }
      }
      else if (this.value === '2') {
        this.money = num * 0.0008
        if (this.money < 150) {
          this.money = 150
        }
      }
      else if (this.value === '3') {
        this.money = num * 0.005
        if (this.money < 500) {
          this.money = 500
        }
      }
      if (this.money) {
        this.money = this.money.toFixed(2)
      }
      this.isMony = true
    },
    btnClear() {
      // this.isMony = false
      this.value = ''
      this.input = ''
      this.money = null
      this.isMony = false;
    },
    noShow() {
      this.iscalc = false;
    },
    // 弹出电话
    phoneBox() {

    }
  },
};
</script>

<style lang='less' scoped>
.showImgWechat:hover .wechatImg {
  display: block;
  width: 150px;
  height: 150px;
}

.phoneBoxShowid:hover .showImgPhone {
  display: block;
}

.phoneBoxShowid:hover .phoneBoxShow {
  display: block;
}

.phoneBoxShow {
  display: none;
  position: absolute;
  bottom: 25px;
  right: 100px;
}

.zhezhao {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 5;
  height: 100vh;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: scale(0.5);
  transform: translate(0, 100px);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease;
}

.fid {
  position: fixed;
  right: 0;
  bottom: 10%;
  z-index: 999;
  display: flex;

  .pictrue {
    width: 84px;
    height: 85px;
    margin-top: 20px;
    cursor: pointer;

    img {
      width: 70%;
      height: 70%;
    }
  }

  // .pictrue:last-child {
  //   width: 105px;
  // }


}

.calc {
  position: fixed;
  right: 100px;
  bottom: 10%;
  width: 332px;
  background-color: #316bff;
  padding: 23px 13px 13px 13px;
  border-radius: 6px;

  h3 {
    color: #ffffff;
    text-align: center;
    font-size: 24px;

    &::after {
      content: '';
      width: 10px;
      height: 2px;
    }
  }

  .from {
    background-color: #ffffff;
    box-shadow: 0px 1px 7px 0px rgba(7, 255, 211, 0.44);
    border-radius: 4px;
    padding: 15px;
    margin-top: 20px;

    .from_top {
      ::v-deep .el-select .el-input .el-select__caret {
        color: #E7B234;
      }

      ::v-deep .select-popper {
        // 参照网上其他资料
        z-index: 8888 !important;
      }

      .input {
        margin: 10px 0;
      }

      .button {
        display: flex;

        .clear {
          width: 35%;

          ::v-deep .el-button.is-plain:focus,
          .el-button.is-plain:hover {
            border: 1px solid #dcdfe6;
            color: #606266;
          }

          ::v-deep .el-button {
            border: 1px solid #dcdfe6;
            padding: 15px 20px;
          }
        }

        .star {
          width: 65%;
          margin-left: 10px;

          .el-button {
            border: 1px solid #ffb520;
            padding: 15px 20px;
            color: #ffb520;
          }

          .el-button:focus,
          .el-button:hover {
            background-color: #fff;
            color: #ffb520;
            border: 1px solid #ffb520;
          }

          .icon-qiandai_purse {
            font-size: 2.5em;
            color: #ffb520;
            margin-right: 20px;
          }
        }
      }
    }

    .from_bottom {
      margin-top: 20px;
      background-image: url(@/assets/home/背景.png);
      background-repeat: no-repeat;
      background-size: cover;
      padding: 15px 30px 25px 30px;

      .img {
        width: 34px;
        height: 36px;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

      p {
        color: #ffffff;
        text-align: center;
        font-size: 18px;
        margin-top: 10px;
        letter-spacing: 1px;
      }
    }
  }
}
</style>