<template>
  <header>
    <div class="logo" @click="clickPage()">
      <img v-lazy="require('../../assets/home/logo.png')" alt="" />
    </div>
    <el-menu class="sidebar-el-menu" :default-active="onRoutes" mode="horizontal" background-color="#edf3ff"
      text-color="#000000" active-text-color="#000000 " unique-opened router>
      <template v-for="item in nav">
        <template>
          <el-menu-item :index="item.path" :key="item.path">
            <template slot="title">{{ item.name }}</template>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
    <div class="heard_right">
      <el-button @click="toLoginPage" type="primary">注册/登录</el-button>
      <div class="contact">
        <div class="phone">
          <img v-lazy="require('../../assets/home/phone.png')" alt="" />
        </div>
        <div>400-183-1833</div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isIndex: 0,
      nav: [
        {
          name: "首页",
          path: "/home",
        },
        {
          name: "保函申请",
          path: "/application",
        },
        {
          name: "保函查验",
          path: "/examine",
        },
        {
          name: "帮助中心",
          path: "/help/register",
        },
        {
          name: "商务合作",
          path: "/cooperation",
        },
        {
          name: "保函资讯",
          path: "/news",
        },
        {
          name: "河北CA",
          path: "/hebeiCA",
        },
        {
          name: "关于我们",
          path: "/about",
        },
      ],
    };
  },
  watch: {

  },
  computed: {
    // onRoutes() { return this.$route.path; },
    onRoutes() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
  },
  created() { },
  mounted() { },
  methods: {
    navJump(index, path) {
      this.isIndex = index;
      this.$router.push(path);
    },
    toLoginPage() {
      // this.$router.push('/login')
      // window.open('http://www.haneton.com:18080/warrantee/loginController.do?loginPage');
      this.$router.push("/login");
    },
    clickPage() {
      window.open("https://www.haneton.com");
    },
  },
};
</script>

<style lang='less' scoped>
header {
  display: flex;
  background-color: #edf3ff;
  height: 100px;
  align-items: center;
  justify-content: space-between;
  padding: 0 3%;

  .heard_right {
    display: flex;
    align-items: center;
  }
}

.logo {
  width: 176px;
  height: 45px;

  img {
    width: 100%;
    height: 100%;
  }
}

.contact {
  display: inline-flex;
  align-items: center;
  color: #1e4abe;
  font-size: 26px;
  font-weight: bold;

  .phone {
    width: 48px;
    height: 40px;
    margin: 0 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.el-button--primary {
  background: linear-gradient(180deg, #1a6cde 0%, #3a64f9 100%);
  border-radius: 17px;
  padding: 10px 20px;
}

::v-deep .el-menu--horizontal>.el-menu-item {
  font-size: 16px;
  font-weight: bold;
  height: 97px;
  line-height: 97px;
}

::v-deep .el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 3px solid #ffcb4e !important;
}

::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background-color: #edf3ff !important;
}

.contact {
  display: inline-flex;
  align-items: center;
  color: #1e4abe;
  font-size: 26px;
  font-weight: bold;

  .phone {
    width: 48px;
    height: 40px;
    margin-right: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>