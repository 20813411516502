var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"all"},[_c('div',{staticClass:"footer"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"qrcode"},[_c('div',{staticClass:"official"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('../../assets/weicode.png')),expression:"require('../../assets/weicode.png')"}],attrs:{"alt":""}}),_c('p',[_vm._v("微信客服")])]),_c('div',{staticClass:"official"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require("../../assets/gongcode.jpg")),expression:"require(\"../../assets/gongcode.jpg\")"}],attrs:{"alt":""}}),_c('p',[_vm._v("公众号")])])])]),_c('div',{staticClass:"maxBottom"},[_c('div',{staticClass:"bottom"},[_vm._m(4),_c('p',{staticClass:"number"},[_vm._v("公安备案号:110102000450")]),_c('p',{staticClass:"number"},[_vm._v("©2022河北筑正科技有限公司")]),_c('p',{staticClass:"number"},[_c('router-link',{attrs:{"to":"/policy"}},[_vm._v("隐私政策")])],1),_vm._m(5)]),_c('div',{staticClass:"minbox"},[_vm._v(" 声明：本平台仅提供技术支持，所有银行保函/公司保函产品均由保函下发银行/担保公司提供 ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("保函服务")]),_c('li',[_vm._v("履约保函")]),_c('li',[_vm._v("投标保函")]),_c('li',[_vm._v("预付款保函")]),_c('li',[_vm._v("农民工工资支付保函")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("保函查验")]),_c('li',[_vm._v("点击查看保函真伪")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("友情链接")]),_c('li',[_c('a',{attrs:{"href":"http://47.118.59.207:11100/login","target":"_blank"}},[_vm._v("邢台电子保函中心")])]),_c('li',[_c('a',{attrs:{"href":"http://47.118.59.207:9400/login","target":"_blank"}},[_vm._v("藁城电子保函中心")])]),_c('li',[_c('a',{attrs:{"href":"http://47.118.59.207:9700/login","target":"_blank"}},[_vm._v("鹿泉电子保函中心")])]),_c('li',[_c('a',{attrs:{"href":"http://47.92.69.180:8110/","target":"_blank"}},[_vm._v("赵县电子保函中心")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("关于我们")]),_c('li',[_vm._v("服务热线：400-183-1833")]),_c('li',[_vm._v("商务合作：0311-85096768")]),_c('li',[_vm._v("电子邮箱：haneton@163.com")]),_c('li',[_vm._v("公司地址：河北省石家庄市新华区中华北大街27号鑫明商务中心211")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("ICP备:冀ICP备19022994号 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticStyle:{"position":"relative","display":"inline-block","margin":"5px 0px 0px 15px","height":"38px"},attrs:{"href":"https://xyt.xcc.cn/getpcInfo?sn=1623271612784525312&language=CN&certType=8&url=www.haneton.com","target":"_blank"}},[_c('div',{staticStyle:{"width":"100%","height":"100%","position":"absolute","top":"0","left":"0"}}),_c('embed',{attrs:{"src":"https://program.xinchacha.com/web/1623271612784525312=www.haneton.com.svg","width":"103","height":"38","type":"image/svg+xml","pluginspage":"//www.adobe.com/svg/viewer/install/"}})])
}]

export { render, staticRenderFns }