<template>
  <div class="all">
    <div class="footer">
      <ul>
        <li>保函服务</li>
        <li>履约保函</li>
        <li>投标保函</li>
        <li>预付款保函</li>
        <li>农民工工资支付保函</li>
      </ul>
      <ul>
        <li>保函查验</li>
        <li>点击查看保函真伪</li>
      </ul>
      <ul>
        <li>友情链接</li>
        <li>
          <a href="http://47.118.59.207:11100/login" target="_blank">邢台电子保函中心</a>
        </li>
        <li>
          <a href="http://47.118.59.207:9400/login" target="_blank">藁城电子保函中心</a>
        </li>
        <li>
          <a href="http://47.118.59.207:9700/login" target="_blank">鹿泉电子保函中心</a>
        </li>
        <li>
          <a href="http://47.92.69.180:8110/" target="_blank">赵县电子保函中心</a>
        </li>
      </ul>
      <ul>
        <li>关于我们</li>
        <li>服务热线：400-183-1833</li>
        <li>商务合作：0311-85096768</li>
        <li>电子邮箱：haneton@163.com</li>
        <li>公司地址：河北省石家庄市新华区中华北大街27号鑫明商务中心211</li>
      </ul>
      <div class="qrcode">
        <div class="official">
          <img v-lazy="require('../../assets/weicode.png')" alt="" />
          <p>微信客服</p>
        </div>
        <div class="official">
          <img v-lazy='require("../../assets/gongcode.jpg")' alt="" />
          <p>公众号</p>
        </div>
      </div>
    </div>
    <div class="maxBottom">
      <div class="bottom">
        <p>
          <a href="https://beian.miit.gov.cn/" target="_blank">ICP备:冀ICP备19022994号 </a>
        </p>
        <p class="number">公安备案号:110102000450</p>
        <p class="number">©2022河北筑正科技有限公司</p>
        <p class="number"><router-link to="/policy">隐私政策</router-link></p>
        <!-- <p class="number">网站地图</p> -->
        <a href="https://xyt.xcc.cn/getpcInfo?sn=1623271612784525312&language=CN&certType=8&url=www.haneton.com"
          target="_blank" style="position: relative;display: inline-block;margin:5px 0px 0px 15px;height: 38px;">
          <div style="width:100%;height:100%;position: absolute;top: 0;left: 0;"></div><embed
            src="https://program.xinchacha.com/web/1623271612784525312=www.haneton.com.svg" width="103" height="38"
            type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/" />
        </a>
      </div>
      <div class="minbox">
        声明：本平台仅提供技术支持，所有银行保函/公司保函产品均由保函下发银行/担保公司提供
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() { },
  mounted() { },
  methods: {},
};
</script>

<style lang='less' scoped>
.all {
  color: #000000;
  position: relative;
  bottom: 0;
}

.footer {
  padding: 2% 0;
  width: 1200px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  text-align: left;

  ul {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    li:first-child {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
    }
  }

  ul li {
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 600;
  }

  .qrcode {
    display: flex;

    .official {
      font-size: 13px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        margin: 0;
        padding: 5px 0;
      }

      img {
        width: 100px;
      }
    }

    .official:first-child {
      margin-right: 10px;
    }
  }
}

a {
  color: #000000;
}

.maxBottom {
  background-color: #efefef;
  font-size: 12px;

  .bottom {
    height: 50px;
    line-height: 50px;
    display: flex;
    justify-content: center;

    .number {
      margin-left: 30px;
    }
  }

  .minbox {
    text-align: center;
    height: 30px;
    line-height: 10px;
  }
}
</style>